import { derived } from "overmind";
import { Todo, User } from "../types";

export type StateApp = {
  isInitialized: boolean;
  isLoading: boolean;
};

export type StateUser = {
  isAuthenticated: boolean;
  data: User | null;
};

export type StateData = {
  todos: {
    isLoading: boolean;
    data: Todo[];
  };
};

export type State = {
  app: StateApp;
  user: StateUser;
  data: StateData;
};

export const state: State = {
  app: {
    isInitialized: false,
    isLoading: false,
  },
  user: {
    isAuthenticated: derived((user: StateUser) => user.data !== null),
    data: null,
  },
  data: {
    todos: {
      isLoading: true,
      data: [],
    },
  },
};
