import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import React from "react";
import { PageContainer } from "../../components/PageContainer";
import { useStore } from "../../store";

export const Account = () => {
  const {
    state: { user },
    actions,
  } = useStore();

  const onClickLogin = async () => {
    await actions.user.doSignIn();
  };

  const onClickLogout = async () => {
    await actions.user.doSignOut();
  };

  return (
    <PageContainer title="Account">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {user.data ? (
          <Grid item xs={12}>
            <List>
              <ListItem>
                <ListItemAvatar>
                  <Avatar alt={user.data.name} src="/broken-image.jpg" />
                </ListItemAvatar>
                <ListItemText
                  primary={user.data.name}
                  secondary={user.data.email}
                />
                <ListItemSecondaryAction>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => onClickLogout()}
                  >
                    Logout
                  </Button>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </Grid>
        ) : (
          <Grid xs={12} container item justify="center">
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<HowToRegIcon />}
              onClick={() => onClickLogin()}
            >
              <Typography component="div" variant="body1">
                Sign in with Google
              </Typography>
            </Button>
          </Grid>
        )}
      </Grid>
    </PageContainer>
  );
};
