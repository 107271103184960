import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import orderBy from "lodash/orderBy";
import React from "react";
import { Todo } from "../../types";

export const TodoList = (props: {
  todos: Todo[];
  onEdit: (todo: Todo) => void;
  onDelete: (todo: Todo) => void;
}) => {
  const { onEdit, onDelete } = props;
  const todos = orderBy(props.todos, "createdAt", "desc");
  return (
    <>
      <List>
        {todos.map((todo) => (
          <ListItem key={todo.$id}>
            <ListItemText primary={todo.title} secondary={todo.createdAt} />
            <ListItemSecondaryAction>
              <IconButton onClick={() => onEdit(todo)}>
                <EditIcon fontSize="small" />
              </IconButton>
              <IconButton edge="end" onClick={() => onDelete(todo)}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </>
  );
};
