import { Array, Record, String } from "runtypes";

export const RtUser = Record({
  $id: String,
  name: String,
  email: String,
});

export const RtTodoNew = Record({
  title: String,
});

export const RtTodo = Record({
  $id: String,
  title: String,
  createdAt: String,
});

export const RtTodosRequest = Record({
  documents: Array(RtTodo),
});
