import { OnInitialize } from "overmind";

export const onInitialize: OnInitialize = async ({
  effects,
  actions,
  state,
}) => {
  const result = await effects.user.get();
  if (result.ok) {
    state.user.data = result.val;
    actions.data.load();
  }
  state.app.isInitialized = true;
};
