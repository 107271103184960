import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import React, { useState } from "react";
import { PageContainer } from "../../components/PageContainer";
import { TodoList } from "../../components/TodoList";
import { useStore } from "../../store";
import { Todo } from "../../types";

export const Todos = () => {
  const {
    state: {
      data: { todos },
    },
    actions,
  } = useStore();

  const [title, setTitle] = useState("");

  const onSubmit = async () => {
    await actions.data.add({ title });
    setTitle("");
  };

  const onEdit = async (todo: Todo) => {
    const response = prompt(`Update: "${todo.title}"`, todo.title);
    await actions.data.update({ ...todo, title: response ?? todo.title });
  };

  const onDelete = async (todo: Todo) => {
    await actions.data.delete(todo);
  };

  return (
    <PageContainer title="Todos">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={8}>
          <TextField
            label="Title"
            variant="filled"
            fullWidth
            size="small"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Grid>
        <Grid xs={4} item container alignItems="center">
          <Button variant="contained" fullWidth onClick={() => onSubmit()}>
            create
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TodoList todos={todos.data} onEdit={onEdit} onDelete={onDelete} />
        </Grid>
      </Grid>
    </PageContainer>
  );
};
