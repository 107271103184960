import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import { ThemeProvider } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import React from "react";
import { FullPageLoader } from "./components/FullPageLoader";
import { useStore } from "./store";
import { theme, useStyles } from "./theme";
import { Account } from "./views/Account";
import { Todos } from "./views/Todos";

export const App = () => {
  const classes = useStyles();
  const { state } = useStore();

  const [tab, setTab] = React.useState(1);

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <main className={classes.content}>
          {state.app.isInitialized === false ? (
            <FullPageLoader />
          ) : (
            <>
              <Paper square>
                <Tabs
                  value={tab}
                  indicatorColor="primary"
                  textColor="primary"
                  centered
                  onChange={(e, value) => setTab(value)}
                >
                  <Tab label="Todos" disabled={!state.user.isAuthenticated} />
                  <Tab label="Account" />
                </Tabs>
              </Paper>
              {tab === 0 ? <Todos /> : <Account />}
            </>
          )}
        </main>
      </ThemeProvider>
    </>
  );
};
