import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import React, { ReactNode } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(6),
    width: "100%",
  },
  title: {
    fontWeight: "bold",
  },
}));

export const PageContainer = (props: {
  title?: string;
  children?: ReactNode;
  action?: () => void;
  actionTitle?: string;
}) => {
  const classes = useStyles();
  const { title, action, actionTitle, children } = props;

  return (
    <div className={classes.root}>
      <Container maxWidth="sm">
        <Grid container spacing={4}>
          {title && (
            <Grid item xs={action ? 8 : 12}>
              <Typography variant="h2" className={classes.title}>
                {title}
              </Typography>
            </Grid>
          )}
          {action && (
            <Grid item xs={title ? 4 : 12} justify="flex-end" container>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => action()}
              >
                {actionTitle || "new"}
              </Button>
            </Grid>
          )}
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
