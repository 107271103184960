import { Result } from "option-t/cjs/PlainResult";
import { AsyncAction } from "overmind";
import { Todo, TodoNew } from "../types";

export const user: {
  doSignIn: AsyncAction;
  doSignOut: AsyncAction;
} = {
  doSignIn: async ({ state, effects }) => {
    state.app.isLoading = true;

    await effects.user.signIn();

    state.app.isLoading = false;
  },
  doSignOut: async ({ state, effects }) => {
    state.app.isLoading = true;

    const result = await effects.user.signOut();

    if (result.ok) {
      state.user.data = null;
    }

    state.app.isLoading = false;
  },
};

export const data: {
  load: AsyncAction;
  add: AsyncAction<TodoNew, Result<Todo, any>>;
  update: AsyncAction<Todo, Result<any, any>>;
  delete: AsyncAction<Todo, Result<any, any>>;
} = {
  load: async ({ state, effects }) => {
    const result = await effects.data.todos.getAll();

    if (result.ok) {
      state.data.todos = {
        isLoading: false,
        data: result.val,
      };
    }
  },
  add: async ({ state, effects }, payload) => {
    const result = await effects.data.todos.add(payload);
    if (result.ok) {
      state.data.todos.data.push(result.val);
    }
    return result;
  },
  update: async ({ state, effects }, payload) => {
    const result = await effects.data.todos.update(payload);
    if (result.ok) {
      state.data.todos.data = [
        ...state.data.todos.data.filter((i) => i.$id !== payload.$id),
        payload,
      ];
    }
    return result;
  },
  delete: async ({ state, effects }, payload) => {
    const result = await effects.data.todos.delete(payload.$id);
    if (result.ok) {
      state.data.todos.data = [
        ...state.data.todos.data.filter((i) => i.$id !== payload.$id),
      ];
    }
    return result;
  },
};
