import { createErr, createOk } from "option-t/cjs/PlainResult";
import { RtTodo, RtTodosRequest, RtUser } from "../runtypes";
import { appwrite } from "../services/AppwriteClient";
import { APP_COLLECTION_ID, APP_HOST } from "../services/AppwriteClient/config";
import { Todo, TodoNew } from "../types";

const TODOS_COLLECTION_ID = APP_COLLECTION_ID;

export const user = {
  get: async () => {
    try {
      const response = await appwrite.account.get();
      const user = RtUser.check(response);
      return createOk(user);
    } catch (error) {
      console.log(error);
      return createErr(error);
    }
  },
  signIn: async () => {
    try {
      await appwrite.account.createOAuth2Session(
        "google",
        `${APP_HOST}/?auth=success`,
        `${APP_HOST}/?auth=failure`
      );
      return createOk(null);
    } catch (error) {
      console.log(error);
      return createErr(error);
    }
  },
  signOut: async () => {
    try {
      await appwrite.account.deleteSessions();
      return createOk(null);
    } catch (error) {
      console.log(error);
      return createErr(error);
    }
  },
};

export const data = {
  todos: {
    getAll: async () => {
      try {
        const response = await appwrite.database.listDocuments(
          TODOS_COLLECTION_ID,
          [],
          100,
          0,
          "createdAt",
          "DESC"
        );
        const result = RtTodosRequest.check(response);
        return createOk(result.documents);
      } catch (error) {
        console.log(error);
        return createErr(error);
      }
    },
    add: async (data: TodoNew) => {
      try {
        const response = await appwrite.database.createDocument(
          TODOS_COLLECTION_ID,
          {
            ...data,
            createdAt: new Date().toISOString(),
          }
        );
        const result = RtTodo.check(response);
        return createOk(result);
      } catch (error) {
        console.log(error);
        return createErr(error);
      }
    },
    update: async (data: Todo) => {
      try {
        const response = await appwrite.database.updateDocument(
          TODOS_COLLECTION_ID,
          data.$id,
          {
            title: data.title,
          }
        );
        const result = RtTodo.check(response);
        return createOk(result);
      } catch (error) {
        console.log(error);
        return createErr(error);
      }
    },
    delete: async (id: string) => {
      try {
        await appwrite.database.deleteDocument(TODOS_COLLECTION_ID, id);
        return createOk(id);
      } catch (error) {
        console.log(error);
        return createErr(error);
      }
    },
  },
};
